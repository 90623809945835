import React from 'react';
import styled from 'styled-components';
import { APP_STYLES } from '../../config.js';
import Icon from "../Icon";

const {
  primaryColor,
  moduleBgColor
} = APP_STYLES;

const Styled404 = styled.div`
  width: 100%;
  height: 100vh;
  padding: 80px 0;
  background-color: ${moduleBgColor};
  text-align: center;
`

function NotFound() {
  return (
    <Styled404>
      <Icon icon="battery-charging" size={300} color={primaryColor} />
    </Styled404>
  );
}

export default NotFound;