import React from "react";
import propTypes from 'prop-types';
import IcomoonReact, { iconList } from "icomoon-react";
import iconSet from "/assets/icons.json";

function Icon(props) {
  const {
    size,
    icon,
    color,
    className,
    customStyles
  } = props;

  return (
    <IcomoonReact
      className={className}
      style={customStyles}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
}

Icon.propTypes = {
  size: propTypes.number,
  icon: propTypes.string.isRequired,
  color: propTypes.string,
  className: propTypes.string,
  customStyles: propTypes.object
};
 
export default Icon;