import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import BeRightBack from './components/BeRightBack';
import { APP_STYLES } from './config.js';

ReactGA.initialize('UA-162042839-1');

const {
  primaryColorDark
} = APP_STYLES;

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: ${primaryColorDark};
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;

    .ReactModalPortal {
      position: relative;
      z-index: 99999;
    }
  }
`

function App() {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Router>
        <BeRightBack />
      </Router>
    </React.Fragment>
  );
}

export default App;