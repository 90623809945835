import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import NotFound from '../NotFound';

const StyledContentWrapper = styled.div`
  min-height: 100vh;
`;

function BeRightBack() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <StyledContentWrapper>
        <Switch>
          <Route component={NotFound} />
        </Switch>
      </StyledContentWrapper>
    </React.Fragment>
  );
}

export default BeRightBack;