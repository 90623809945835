export const APP_CONFIG = {
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px'
  }
};

export const APP_STYLES = {
  primaryColor: '#26F2CB',
  primaryColorDark: '#01CEA6',
  primaryColorContrast: '#FFFFFF',
  secondaryColor: '#1D202D',
  bodyTextColor: '#a0a0a0',
  moduleBgColor: '#FFFFFF'
};